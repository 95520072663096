import React, { useEffect, useState, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import AutoCompleteTextarea from './AutoCompleteTextarea';
import './canvas.css';
import InputArea from './InputArea';
import LogViewer from './Logviewer';

function Canvas() {
  const [isTypeScript, setIsTypeScript] = useState(false);
  const [htmlCode, setHtmlCode] = useState('');
  const [cssCode, setCssCode] = useState('');
  const [jsCode, setJsCode] = useState('');
  const [isTailwindEnabled, setIsTailwindEnabled] = useState(false);
  const [projname, setProjname] = useState('');
  const [projects, setProjects] = useState([]);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [isConfirmVisible, setIsConfirmVisible] = useState(false);
  const [isInputAreaVisible, setIsInputAreaVisible] = useState(false);
  const [messages, setMessages] = useState([]);
  const [expandedArea, setExpandedArea] = useState(null);
  const [isSliderAreaVisible, setIsSliderAreaVisible] = useState(false);
  const [is3DViewVisible, setIs3DViewVisible] = useState(false);
  const [projectId, setProjectId] = useState(null); // Add projectId state
  const [isShared, setIsShared] = useState(false);
  const [sharedLinkVisible, setSharedLinkVisible] = useState(false);
  const [sharedLink, setSharedLink] = useState("");
  const [isAutoRun, setIsAutoRun] = useState(true);
  const ws = useRef(null); // WebSocket reference

  const toggleExpandArea = (area) => {
    setExpandedArea(prevArea => (prevArea === area ? null : area));
  };

  const toggleSliderArea = () => {
    setIsSliderAreaVisible(!isSliderAreaVisible);
  };

  const toggle3DView = () => {
    setIs3DViewVisible(!is3DViewVisible);
  };

  const saveCurrentProject = async () => {
    const token = localStorage.getItem('token');
    const id = projectId || uuidv4(); // Use existing projectId or generate a new one
    setProjectId(id);

    try {
      const response = await fetch('/api/v1/saveProject', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ id, projname, htmlCode, cssCode, jsCode }),
      });

      if (response.status === 403) {
        console.error('No token provided or token is invalid');
      } else {
        const result = await response.json();
        console.log('Project saved:', result);
      }
    } catch (error) {
      console.error('Error saving project:', error);
    }
  };

  const getProjects = async () => {
    try {
      const token = localStorage.getItem('token');

      const response = await fetch('/api/v1/projects', {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      const projects = await response.json();

      console.log('Projects fetched:', projects);
      setProjects(projects);
      setIsPopupVisible(true);
    } catch (error) {
      console.error('Error fetching projects:', error);
    }
  };

  const loadProject = (project) => {
    setSelectedProject(project);
    setIsConfirmVisible(true);
  };

  const confirmLoadProject = () => {
    setHtmlCode('');
    setCssCode('');
    setJsCode('');

    if (selectedProject) {
      setHtmlCode(selectedProject.html);
      setCssCode(selectedProject.css);
      setJsCode(selectedProject.js);
      setProjectId(selectedProject.id); // Set the projectId
    }

    setIsConfirmVisible(false);
    setIsPopupVisible(false);
  };

  const cancelLoadProject = () => {
    setIsConfirmVisible(false);
    setSelectedProject(null);
  };

  const generateShareableLink = async () => {
    const id = projectId || uuidv4(); 
    setProjectId(id);
  
    try {
      const response = await fetch('/api/v1/generateShareableLink', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ projectId: id }),
      });
  
      const { token } = await response.json();
      const shareableLink = `${window.location.origin}/canvas?project=${id}&token=${token}`;
      setSharedLink(shareableLink);
      navigator.clipboard.writeText(shareableLink)
      localStorage.removeItem('sharedProjectToken');
      localStorage.setItem('sharedProjectToken', token);
      localStorage.setItem('sharedProjectId', id);
      setIsShared(true);
  
      // Initialize WebSocket for the original user
      ws.current = new WebSocket(import.meta.env.VITE_WS_URL);
  
      ws.current.onopen = async () => {
        console.log('WebSocket connected');
    
      };
    } catch (error) {
      console.error('Error generating shareable link:', error);
    }
  };
  
  useEffect(() => {
    const init = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const id = urlParams.get('project') || localStorage.getItem('sharedProjectId');
      const token = urlParams.get('token') || localStorage.getItem('sharedProjectToken');

      if(id && token) {
        if(localStorage.getItem('sharedProjectToken')){
          localStorage.removeItem('sharedProjectToken')
          console.log('token removed before resetting')
        }
        localStorage.setItem('sharedProjectId', id);
        localStorage.setItem('sharedProjectToken', token)
        setIsShared(true);
        setProjectId(id);
        console.log(`Load project with ID: ${id}`);
        
        } else {
          setIsShared(false);
          console.log('No valid project or token found in URL or localStorage');
        }
      };
    init();
  }, []);
  
  const reconnectWebSocket = () => {
    setTimeout(() => {
      console.log('Attempting to reconnect WebSocket...');
      ws.current = new WebSocket(import.meta.env.VITE_WS_URL);
    }, 3000); // Attempt to reconnect after 3 seconds
  };

  useEffect(() => {
    if (projectId === null || !isShared) {
      return;
    }
  
    // Avoid creating multiple WebSocket instances
    if (!ws.current || ws.current.readyState === WebSocket.CLOSED) {
      ws.current = new WebSocket(import.meta.env.VITE_WS_URL); 
  
      ws.current.onopen = () => {
        console.log('WebSocket connected');
      };
  
      ws.current.onmessage = (event) => {
        console.log('Received WebSocket message:', event.data);
      
        
        const message = JSON.parse(event.data);
        console.log('Parsed message:', message);
      
        if (message.type === 'batchUpdate' && message.projectId === projectId) {
          console.log('Processing batch update:', message.updates);
      
          message.updates.forEach((update) => {
            const { field, value } = update;
            console.log(`Updating ${field} with value:`, value);
      
            if (field === 'htmlCode') {
              setHtmlCode(value);
              console.log('HTML Code updated');
            }
            if (field === 'cssCode') {
              setCssCode(value);
              console.log('CSS Code updated');
            }
            if (field === 'jsCode') {
              setJsCode(value);
              console.log('JS Code updated');
            }
          });
        }
      };
      
  
      ws.current.onclose = () => {
        console.log('WebSocket disconnected');
        reconnectWebSocket();
      };
    }
  
    return () => {
      if(ws.current){

        ws.current.close();
      }
    
    };
  }, [projectId, isShared]);

  let lastLocalUpdate = Date.now();

  const handleChange = (field, value) => {
    lastLocalUpdate = Date.now(); // Update the local timestamp
  
    if (field === 'htmlCode') setHtmlCode(value);
    if (field === 'cssCode') setCssCode(value);
    if (field === 'jsCode') setJsCode(value);
  
    if (isShared && ws.current && ws.current.readyState === WebSocket.OPEN) {
      const message = JSON.stringify({
        type: 'batchUpdate',
        updates: [{ field, value, timestamp: lastLocalUpdate }],
        projectId
      });
      ws.current.send(message);
    } else if (isShared) {
      // console.error('WebSocket is not connected');
    }
  };
  if(ws.current) {

    ws.current.onmessage = (event) => {
      // console.log('Received WebSocket message:', event.data);
      
      const message = JSON.parse(event.data);
      // console.log('Parsed message:', message);
      
      if (message.type === 'batchUpdate' && message.projectId === projectId) {
        message.updates.forEach((update) => {
          const { field, value, timestamp } = update;
          // console.log(`Updating ${field} with value:`, value);
          
          if (timestamp > lastLocalUpdate) { // Only update if the server's update is newer
            if (field === 'htmlCode') {
              setHtmlCode(value);
              // console.log('HTML Code updated');
            }
            if (field === 'cssCode') {
              setCssCode(value);
              // console.log('CSS Code updated');
            }
            if (field === 'jsCode') {
              setJsCode(value);
              // console.log('JS Code updated');
            }
          }
        });
      }
    }
    }


  


  useEffect(() => {
    async function loadFiles() {
      const randomNum = Math.floor(Math.random() * 38) + 1;

      try {
        const htmlModule = await import(`./assets/defaultHtml${randomNum}.js`);
        const { defaultHtml } = htmlModule;
        setHtmlCode(defaultHtml);

        const cssModule = await import(`./assets/defaultCss${randomNum}.js`);
        const { defaultCss } = cssModule;
        setCssCode(defaultCss);

        const jsResponse = await fetch(`./assets/defaultJs${randomNum}.txt`);
        const jsContent = await jsResponse.text();
        setJsCode(jsContent);
      } catch (error) {
        console.error('Error loading files:', error);
      }
    }

    loadFiles();
  }, []);

  const renderOutput = () => {
    const output = document.getElementById("output");

    let jsContent = jsCode;
    if (isTypeScript) {
      jsContent = window.ts.transpile(jsCode);
    }

    const tailwindScript = isTailwindEnabled
      ? `<script defer src="https://cdn.tailwindcss.com"></script>`
      : "";

    const source = `
      <html>
      <head>
        <script defer src="https://cdn.jsdelivr.net/npm/gsap@3.12.5/dist/ScrollTrigger.min.js"></script>
        <script src="https://cdn.jsdelivr.net/npm/typescript@latest"></script>
        <script defer src="https://cdnjs.cloudflare.com/ajax/libs/sass.js/0.9.2/sass.min.js"></script>
        <script defer src="https://cdn.jsdelivr.net/npm/gsap@3.12.5/dist/gsap.min.js"></script>
        <script defer src="https://cdn.jsdelivr.net/npm/gsap@3.12.5/dist/ScrollTrigger.min.js"></script>
        <script defer src="https://cdnjs.cloudflare.com/ajax/libs/codemirror/5.58.1/codemirror.js"></script>
        ${tailwindScript}
        <style>${cssCode}</style>
      </head>
      <body>
        ${htmlCode}
        <script type="module">${jsContent}<\/script>
      </body>
      </html>
    `;

    const blob = new Blob([source], { type: 'text/html' });
    const url = URL.createObjectURL(blob);
    output.src = url;
  };

  useEffect(() => {
    if(isAutoRun) {
      renderOutput();
    }
  }, [htmlCode, cssCode, jsCode, isTypeScript, isTailwindEnabled, isAutoRun]);

  const downloadFiles = (event) => {
    // event.preventDefault();

    const htmlContent = `export const defaultHtml = \`${htmlCode}\`;`;
    const htmlBlob = new Blob([htmlContent], { type: 'text/javascript' });
    const htmlUrl = URL.createObjectURL(htmlBlob);
    const htmlLink = document.createElement('a');
    htmlLink.href = htmlUrl;
    htmlLink.download = 'defaultHtml.js';
    htmlLink.click();

    const cssContent = `export const defaultCss = \`${cssCode}\`;`;
    const cssBlob = new Blob([cssContent], { type: 'text/javascript' });
    const cssUrl = URL.createObjectURL(cssBlob);
    const cssLink = document.createElement('a');
    cssLink.href = cssUrl;
    cssLink.download = 'defaultCss.js';
    cssLink.click();

    const jsBlob = new Blob([jsCode], { type: 'text/plain' });
    const jsUrl = URL.createObjectURL(jsBlob);
    const jsLink = document.createElement('a');
    jsLink.href = jsUrl;
    jsLink.download = 'defaultJs.txt';
    jsLink.click();
  };

  useEffect(() => {
    const listItems = document.querySelectorAll(".sidebar-menu li");

    listItems.forEach((listItem) => {
      listItem.addEventListener("click", () => {
        listItems.forEach((otherItem) => {
          otherItem.classList.remove('active');
        });
        listItem.classList.add('active');
      });
    });
  }, []);

  const handleQuerySubmit = async (query) => {
    setMessages((prevMessages) => [...prevMessages, { sender: 'user', text: query }]);

    try {
      const response = await fetch('/generate-css', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userQuery: query }),
      });

      const data = await response.json();
      console.log('API Response:', data);

      const parsed = data.response;

      setMessages((prevMessages) => [...prevMessages, { sender: 'ai', text: data.cssCode }]);

    } catch (error) {
      console.error('Error generating CSS:', error);
      setMessages((prevMessages) => [...prevMessages, { sender: 'ai', text: 'Error generating response' }]);
    }
  };
  
  const toggleInputArea = () => {
    setIsInputAreaVisible(!isInputAreaVisible);
  };


  const handleCreateNewProject = () => {
    const newProjectId = uuidv4();
    const newToken = uuidv4(); 
  
    
    console.log('Creating new project:');
    console.log('New Project ID:', newProjectId);
    console.log('New Token ID:', newToken);
  
    
    saveCurrentProject();
  
    // Load the new project template or empty project
    setProjectId(newProjectId);
    setHtmlCode(''); 
    setCssCode('');  
    setJsCode('');   
    setProjname(''); 
    setIsShared(false); 
  
    // update url with new token and projid, not sure if i need this yet
    // window.history.pushState(null, null, `/canvas?project=${newProjectId}&token=${newToken}`);
  };
  

  return (
    <div>
      <div className="control-panel">
        <label className="switch">
          <input type="checkbox" checked={isAutoRun} onChange={() => setIsAutoRun(!isAutoRun)}
        />
        <span className="slider round"></span>
        </label>
        <button onClick={isAutoRun ? null : renderOutput} 
        className={`run-button ${isAutoRun ? 'disabled' : ''}`}
        >Run Code{isAutoRun ? '' : ''}
        </button>
        </div>
        
      <div className='envelope-containers'>
      <button className={`button-common envelope-tab ${isInputAreaVisible ? 'active' : ''}`} onClick={toggleInputArea}>
        Click Here To Ask AI
        </button>
      <button
       className={`button-common generate-share envelope-tab ${sharedLinkVisible ? 'active' : ''}`} 
       onClick={() => setSharedLinkVisible(true)}>
      {sharedLinkVisible ? 'Start/Stop/Copy Link' : 'Invite Others To Collab'}
      </button>
      <LogViewer />
      </div>

      {sharedLinkVisible && (
        <div className="popup log-popup">
          <div className="popup-content">
            <h3>Shareable Link</h3>
            <button className="close-console" onClick={() => navigator.clipboard.writeText(sharedLink)}>
              Copy Link
            </button>
            <h3>{sharedLink}</h3>
            <button className="close-console" onClick={() => {
              localStorage.removeItem('sharedProjectToken');
              generateShareableLink();
            }}>Start New Session
              </button>

            <button className="close-console" onClick={() => {
              // Logic to end sharing session(need to add later on)
              localStorage.removeItem('sharedProjectToken');
              setSharedLinkVisible(false);
            }}>
              End Sharing Session
            </button>
            <button className="close-console" onClick={() => setSharedLinkVisible(false)}>Close</button>
          </div>
        </div>
      )}


        
      {isInputAreaVisible && (
        
        <div className="input-area-and-conversation-container">
          <InputArea onSubmit={handleQuerySubmit} />
          <ConversationView messages={messages} />
          </div>
      )}
        
      <nav className="canvas-sidebar-menu">
        <ul>
          <li>
            <a href="/">
              <i className="fa fa-home nav-icon"></i>
              <span className="nav-text">Home</span>
            </a>
          </li>
          <li>
            <a href="#" onClick={handleCreateNewProject}>
              <i className="fa fa-image nav-icon"></i>
              <span className="nav-text">New Project</span>
            </a>
          </li>
          <li>
            <a href="#" onClick={saveCurrentProject}>
              <i className="fa fa-save nav-icon"></i>
              <span className="nav-text">Save Current</span>
            </a>
          </li>
          <li>
            <a href="#" onClick={getProjects}>
              <i className="fa fa-folder-open nav-icon"></i>
              <span className="nav-text">My Projects</span>
            </a>
          </li>
          <li>
            <a href="#">
              <i className="fa fa-bell nav-icon"></i>
              <span className="nav-text">Notification</span>
            </a>
          </li>
        </ul>

        <ul className="logout">
          <li>
            <a href="/account">
              <i className="fa fa-cogs nav-icon"></i>
              <span className="nav-text">Settings</span>
            </a>
          </li>
          <li>
            <a href="#">
              <i className="fa fa-right-from-bracket nav-icon"></i>
              <span className="nav-text">Logout</span>
            </a>
          </li>
        </ul>
      </nav>

      {isPopupVisible && (
        <div className="popup-menu">
        <h3>Select a Project</h3>
        <ul>
          {projects.map((project) => (
            <li key={project.id} onClick={() => loadProject(project)}>
              {project.projname}
            </li>
          ))}
        </ul>
        <button onClick={() => setIsPopupVisible(false)}>Close</button>
      </div>
    )}

    {isConfirmVisible && (
      <div className="confirmation-dialog">
        <p>Are you sure you want to load your project? Any unsaved changes on the current canvas will be deleted.</p>
        <button onClick={confirmLoadProject}>Load Project</button>
        <button onClick={cancelLoadProject}>Cancel</button>
      </div>
    )}

    <div className="animated-background">
      <div className="waves"></div>
      
      <div className="container">
        <div className="leftside">
          <div className="editor-box">
            <label>
              Project Name:
              <input 
                type="text" 
                value={projname} 
                onChange={(e) => setProjname(e.target.value)} 
                placeholder="Enter project name" 
                />
            </label>

            <label>
              <i className="fa-brands fa-html5"></i> HTML
              <button onClick={() => navigator.clipboard.writeText(htmlCode)} className="copy-button">
                <i className="fa-regular fa-copy"></i>
              </button>
            </label>
            <AutoCompleteTextarea
              id="html-code"
              value={htmlCode}
              onChange={(e) => handleChange('htmlCode', e.target.value)}
              />
          </div>

          <div className="editor-box">
            <label>
              <i className="fa-brands fa-css3-alt"></i> CSS
              <button onClick={() => navigator.clipboard.writeText(cssCode)} className="copy-button">
                <i className="fa-regular fa-copy"></i>
              </button>
            </label>
            <AutoCompleteTextarea
              id="css-code"
              value={cssCode}
              onChange={(e) => handleChange('cssCode', e.target.value)}
              />
            <label>
              <input
                type="checkbox"
                checked={isTailwindEnabled}
                onChange={() => setIsTailwindEnabled(!isTailwindEnabled)}
                />
              Enable Tailwind
            </label>
          </div>

          <div className="editor-box">
            <label>
              <i className="fa-brands fa-js"></i> JS/TS
              <button onClick={() => navigator.clipboard.writeText(jsCode)} className="copy-button">
                <i className="fa-regular fa-copy"></i>
              </button>
            </label>
            <AutoCompleteTextarea
              id="js-code"
              value={jsCode}
              onChange={(e) => handleChange('jsCode', e.target.value)}
              />

            <div>
              <label>
                <input
                  type="checkbox"
                  checked={isTypeScript}
                  onChange={() => setIsTypeScript(!isTypeScript)}
                  />
                Use TypeScript
              </label>
            </div>
          </div>
        </div>

        <div className="rightside">
          <label><i className="fa-solid fa-play"></i> Output</label>
          <iframe id="output"></iframe>
        </div>
      </div>
  </div>
</div>
  );
}

const ConversationView = ({ messages }) => {
  return (
    <div className="conversation-view">
      {messages.map((message, index) => (
        <div key={index} className={`message ${message.sender}`}>
          <strong>{message.sender === 'user' ? 'You' : 'AI'}:</strong> {message.text}
        </div>
      ))}
    </div>
  );
};

export default Canvas;