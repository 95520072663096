import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

const LogViewer = () => {
  const [logs, setLogs] = useState([]);
  const [showLogs, setShowLogs] = useState(false);
  const [position, setPosition] = useState({ top: '10%', left: '10%' });
  const [size, setSize] = useState({ width: '80%', height: '60%' });
  const [input, setInput] = useState('');
  const [showNetwork, setShowNetwork] = useState(false);
  const [networkLogs, setNetworkLogs] = useState([]);
  const logPopupRef = useRef(null);

  useEffect(() => {
    const originalConsoleLog = console.log;
    const originalFetch = window.fetch;
    const originalXhrSend = XMLHttpRequest.prototype.send;

    console.log = (...args) => {
      requestAnimationFrame(() => {
        setLogs((prevLogs) => {
          const newLogs = [...prevLogs, args.join(' ')];
          return newLogs.length > 100 ? newLogs.slice(newLogs.length - 100) : newLogs;
        });
      });
      originalConsoleLog(...args);
    };

    window.fetch = async (...args) => {
      const [resource, config] = args;
      const startTime = performance.now();
      try {
        const response = await originalFetch(...args);
        const endTime = performance.now();
        setNetworkLogs((prevLogs) => [
          ...prevLogs,
          {
            type: 'fetch',
            url: resource,
            method: config?.method || 'GET',
            status: response.status,
            time: `${(endTime - startTime).toFixed(2)}ms`,
          },
        ]);
        return response;
      } catch (error) {
        const endTime = performance.now();
        setNetworkLogs((prevLogs) => [
          ...prevLogs,
          {
            type: 'fetch',
            url: resource,
            method: config?.method || 'GET',
            status: 'Failed',
            time: `${(endTime - startTime).toFixed(2)}ms`,
          },
        ]);
        throw error;
      }
    };

    XMLHttpRequest.prototype.send = function (...args) {
      const xhr = this;
      const method = this._method || 'GET';
      const url = this._url || '';
      const startTime = performance.now();

      xhr.addEventListener('loadend', function () {
        const endTime = performance.now();
        setNetworkLogs((prevLogs) => [
          ...prevLogs,
          {
            type: 'xhr',
            url,
            method,
            status: xhr.status,
            time: `${(endTime - startTime).toFixed(2)}ms`,
          },
        ]);
      });

      originalXhrSend.apply(this, args);
    };

    const originalXhrOpen = XMLHttpRequest.prototype.open;
    XMLHttpRequest.prototype.open = function (method, url, ...args) {
      this._method = method;
      this._url = url;
      originalXhrOpen.apply(this, [method, url, ...args]);
    };

    return () => {
      console.log = originalConsoleLog;
      window.fetch = originalFetch;
      XMLHttpRequest.prototype.send = originalXhrSend;
      XMLHttpRequest.prototype.open = originalXhrOpen;
    };
  }, []);

  const handleExecuteCommand = () => {
    try {
      const result = eval(input); 
      console.log(result);
      setInput(''); 
    } catch (err) {
      console.log(`Error: ${err.message}`);
    }
  };

  const handleMouseDown = (e) => {
    const startX = e.clientX;
    const startY = e.clientY;
    const { top, left } = logPopupRef.current.getBoundingClientRect();

    const handleMouseMove = (moveEvent) => {
      const newLeft = `${left + moveEvent.clientX - startX}px`;
      const newTop = `${top + moveEvent.clientY - startY}px`;
      setPosition({ top: newTop, left: newLeft });
    };

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleResizeMouseDown = (e) => {
    const startX = e.clientX;
    const startY = e.clientY;
    const startWidth = logPopupRef.current.offsetWidth;
    const startHeight = logPopupRef.current.offsetHeight;

    const handleResizeMouseMove = (moveEvent) => {
      const newWidth = `${startWidth + moveEvent.clientX - startX}px`;
      const newHeight = `${startHeight + moveEvent.clientY - startY}px`;
      setSize({ width: newWidth, height: newHeight });
    };

    const handleResizeMouseUp = () => {
      document.removeEventListener('mousemove', handleResizeMouseMove);
      document.removeEventListener('mouseup', handleResizeMouseUp);
    };

    document.addEventListener('mousemove', handleResizeMouseMove);
    document.addEventListener('mouseup', handleResizeMouseUp);
  };

  const logViewerContent = (
    <div
      className="log-popupv2"
      ref={logPopupRef}
      style={{ top: position.top, left: position.left, width: size.width, height: size.height }}
    >
      <div className="log-popup-headerv2" onMouseDown={handleMouseDown}>
        <button className="close-consolev2" onClick={() => setShowNetwork(!showNetwork)}>
          {showNetwork ? 'Hide Network Tab' : 'Show Network Tab'}
        </button>
        <button className="close-consolev2" onClick={() => setShowLogs(false)}>Close</button>
      </div>
      <div className="logsv2">
        {logs.map((log, index) => (
          <div key={index}>{log}</div>
        ))}
      </div>
      <div>
        <input
          className="console-inputv2"
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Type command and press Enter"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleExecuteCommand();
            }
          }}
        />
      </div>
      {showNetwork && (
        <div className="network-tabv2">
          <h3>Network Requests</h3>
          {networkLogs.map((log, index) => (
            <div key={index} className="network-logv2">
              <div><strong>Type:</strong> {log.type.toUpperCase()}</div>
              <div><strong>URL:</strong> {log.url}</div>
              <div><strong>Method:</strong> {log.method}</div>
              <div><strong>Status:</strong> {log.status}</div>
              <div><strong>Time:</strong> {log.time}</div>
            </div>
          ))}
        </div>
      )}
      <div className="log-popup-resizerv2" onMouseDown={handleResizeMouseDown} />
    </div>
  );

  return (
    <>
      <button
        className={`button-common log-button envelope-tab ${showLogs ? 'active' : ''}`}
        onClick={() => setShowLogs(!showLogs)}
      >
        {showLogs ? 'Hide Console Logs' : 'Show Console Logs'}
      </button>

      {showLogs && ReactDOM.createPortal(logViewerContent, document.body)}
    </>
  );
};

export default LogViewer;
