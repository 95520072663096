import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import HomePage from './homepage.jsx';
import Account from './account.jsx';
import Canvas from './Canvas.jsx';
import Navbar from './navbar.jsx';


const App = () => {
  return (
    
    <Router>
      <Navbar />
        
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/canvas" element={<Canvas />} />
          <Route path="/account" element={<Account />} />
        </Routes>
      
    </Router>
      
    
     
  );
}






export default App;
