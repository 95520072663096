import React, { useEffect } from 'react';

const AutoCompleteTextarea = ({ id, value, onChange }) => {
  useEffect(() => {
    function handleAutoComplete(event) {
      const textarea = event.target;
      const cursorPosition = textarea.selectionStart;
      const textBeforeCursor = textarea.value.substring(0, cursorPosition);
      const textAfterCursor = textarea.value.substring(cursorPosition);

      // match for html tags only. might need to add more
      if (event.key === '>') {
        const tagMatch = textBeforeCursor.match(/<([a-zA-Z][a-zA-Z0-9]*)$/);

        if (tagMatch) {
          const tagName = tagMatch[1];
          const autoCompleteText = `</${tagName}>`;

          
          const newTextValue = `${textBeforeCursor}>${autoCompleteText}${textAfterCursor}`;
          const newCursorPosition = cursorPosition + 1; // Position after the opening tag

          
          textarea.value = newTextValue;
          textarea.selectionStart = textarea.selectionEnd = newCursorPosition;

          
          onChange({ target: { id, value: newTextValue } });

          
          event.preventDefault();
          return;
        }
      }

      // add any brackets i forgot here
      const bracketPairs = {
        '{': '}',
        '(': ')',
        '[': ']',
      };

      if (bracketPairs[event.key]) {
        const autoCompleteBracket = bracketPairs[event.key];

        // Insert the closing bracket and move the cursor back between the brackets
        const newTextValue = `${textBeforeCursor}${event.key}${autoCompleteBracket}${textAfterCursor}`;
        const newCursorPosition = cursorPosition + 1; // Position after the opening bracket

        
        textarea.value = newTextValue;
        textarea.selectionStart = textarea.selectionEnd = newCursorPosition;

        
        onChange({ target: { id, value: newTextValue } });

        
        event.preventDefault();
      }
    }

    const textarea = document.getElementById(id);
    textarea.addEventListener('keydown', handleAutoComplete);

    return () => {
      textarea.removeEventListener('keydown', handleAutoComplete);
    };
  }, [id, onChange]);

  return <textarea id={id} value={value} onChange={onChange}></textarea>;
}

export default AutoCompleteTextarea;
