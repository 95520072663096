import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './account.css';

const Account = () => {
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPasswordInput, setConfirmPasswordInput] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [activeSection, setActiveTab] = useState("");

    const handleButtonClick = (action) => {
        setActiveTab(action);
    };

    const handlePasswordChange = async (e) => {
        e.preventDefault();
    
        if (newPassword !== confirmPasswordInput) {
            setPasswordError("The new passwords do not match");
            return;
        }
    
        try {
            const token = localStorage.getItem('token');
    
            const response = await fetch('api/v1/change-password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    currentPassword, 
                    newPassword      
                }),
            });
    
            if (response.ok) {
                const data = await response.json();
                if (data.success) {
                    console.log("Password changed!");
                } else {
                    setPasswordError(data.message || "Could not set password");
                }
            } else {
                const errorData = await response.json();
                setPasswordError(errorData.message || "Error changing password.");
            }
        } catch (error) {
            setPasswordError("An unexpected error occurred. Please try again later.");
            console.error("Password change error:", error);
        }
    };
    
    
    return (
        <div className="body-account">
            <div className="sidebar">
                <ul className="sidebar-menu">
                    <li><a href="#account" onClick={() => handleButtonClick('account')}>Account</a></li>
                    <li><a href="#password" onClick={() => handleButtonClick('password')}>Password</a></li>
                    <li><a href="#projects" onClick={() => handleButtonClick('projects')}>Projects</a></li>
                    <li><a href="#assets" onClick={() => handleButtonClick('assets')}>Assets</a></li>
                    <li><a href="#preferences" onClick={() => handleButtonClick('preferences')}>Preferences</a></li>
                </ul>
            </div>

            {activeSection === 'account' && (
                <div className="account-form-container">
                    <form className="account-form">
                        <h2>Account Settings</h2>
                    </form>
                </div>
            )}

            {activeSection === 'password' && (
                <div className="password-form-container">
                    <form onSubmit={handlePasswordChange} className="password-form">
                        <h2>Change Password</h2>
                        <input
                            type="password"
                            placeholder="Enter current password"
                            value={currentPassword}
                            onChange={(e) => setCurrentPassword(e.target.value)}
                            required
                        />
                        <input
                            type="password"
                            placeholder="Enter new password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                        />
                        <input
                            type="password"
                            placeholder="Confirm new password"
                            value={confirmPasswordInput}
                            onChange={(e) => setConfirmPasswordInput(e.target.value)}
                            required
                        />
                        {passwordError && <p className="error-message">{passwordError}</p>}
                        <button type="submit">Submit</button>
                        <button type="button" onClick={() => setActiveTab('')}>Cancel</button>
                    </form>
                </div>
            )}
        
        {activeSection === 'preferences' && (
                <div className="preferences-form-container">
                    <form className="preferences-form">
                        <h2>Preferences</h2>
                    </form>
                </div>
            )}
            
            {activeSection === 'projects' && (
                <div className="projects-form-container">
                    <form className="projects-form">
                        <h2>Projects</h2>
                    </form>
                </div>
            )}
            
            {activeSection === 'assets' && (
                <div className="assets-form-container">
                    <form className="assets-form">
                        <h2>Assets</h2>
                    </form>
                </div>
            )}
        </div>
    );
};

export default Account;